<template>
<div style="min-height: 500px">
   <!-- Hero Start -->
        <section class="bg-half w-100" style="background: url('/images/company/aboutus.jpg');">
            <div class="bg-overlay"></div>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-12 text-center">
                        <div class="page-next-level title-heading">
                          <div class="page-next">
                                <nav aria-label="breadcrumb" class="d-inline-block">
                                    <ul class="breadcrumb bg-custom rounded shadow mb-0">
                                        <li class="breadcrumb-item"><a href="javascript:void(0)">Faculty</a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div><!--end col--> 
               </div> <!--end row--> 
            </div> <!--end container-->
        </section><!--end section-->
        <div class="position-relative">
            <div class="shape overflow-hidden text-white">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>
        <!-- Hero End -->
        
      <section class="section">
        <div class="container">
          <listGrid view='grid' :data=listGridData :listRenderer=listRenderer :gridRenderer=gridRenderer caption="Faculties"></listGrid>
        </div>
       
      </section>
    <!-- And many others... -->
</div>
</template>
<script>
import feather from 'feather-icons'
import listGrid from './listGrid';
import gridv from './gridv';
import listv from './listv';
export default {
  name: 'AllFaculty',
  components: {
    listGrid,
  },
  mounted() {
      feather.replace();
    },
  data () {
    return {
      msg: 'List-Grid View Test App',
      listRenderer: gridv,
      gridRenderer: listv,
      listGridData: [{
        title: 'Tapping into UGC with Offerpop',
        url: 'https://voltagead.com/tapping-ugc-offerpop/',
        image: {
          'large': 'https://2e64oz2sjk733hqp882l9xbo-wpengine.netdna-ssl.com/wp-content/uploads/2016/08/header-960x500-copy-960x500.jpg',
          'small': 'https://2e64oz2sjk733hqp882l9xbo-wpengine.netdna-ssl.com/wp-content/uploads/2016/08/header-960x500-copy-300x300.jpg'
        }
      }, {
        title: '5 websites that get design right',
        url: 'https://voltagead.com/5-websites-get-design-right/',
        image: {
          'large': 'https://2e64oz2sjk733hqp882l9xbo-wpengine.netdna-ssl.com/wp-content/uploads/2016/08/HERO-960x500.jpg',
          'small': 'https://2e64oz2sjk733hqp882l9xbo-wpengine.netdna-ssl.com/wp-content/uploads/2016/08/HERO-300x300.jpg'
        }
      }]
    }
  },
  created (){
    this.$store.dispatch('GET_ALL_COMMON_INFO');
    this.$store.dispatch('GET_ALL_EMPLOYEE');
  },
  computed:{
    sub_menu(){
      return this.$store.getters.single_sub_menus(this.slug);
    },
    loader() {
      return this.$store.getters.loader;
    },
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import './../../../assets/css/style-dark.css';
</style>