<template>
		<div class="items-lest-wrapper row bg-custom">
      <div class="row col-md-6 mb-2 mt-2" v-for="(employee,key) in employees" :key="key">
        <div class="col-sm-4">
          <div class="img-wrapper">
            <img :src="employee.image?imgShow(employee.image):'/man_face.jpg'" class="img-fluid" alt="">
          </div>
        </div>
        <div class="col-sm-8 ">
          <div class="item-key">
            <div class="cat-wrapper">
              <div class="row">
                <div class="text_custom" style="border-left: 1px dotted rgba(112, 131, 53);">
                  <div class="cat-2 ml-2">
                    <h4><a href="javascript:void(0)" class="text_custom">{{employee.userable?employee.userable.name:''}}</a></h4>
                    <span
                          class="d-block ">{{ designationByID(employee.designation_id) }}</span>
                    <span
                          class="d-block text-custom2"><span >Dept. of {{ departmentByID(employee.department_id) }}</span></span>
                    <router-link :to="{path:`/faculty/details${employee.id}`}" exact style="display: inline-block;
                                                  background: #f05931;
                                                  padding: 5px 10px;
                                                  margin-top: 25px;
                                                  font-size: 14px;
                                                  color: #fff;
                                                  border-radius: 3px;">
                      <i class="far fa-envelope mr-1" exact></i>View Details</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
  export default
	{
		name: 'listv',
		props: {
			'employees': { default: () => { } }
		},
    methods: {
      imgShow(path){
        return process.env.VUE_APP_ENV_RESOURCHURL+path;
      },
    },
    computed:{
      designationByID(){
        return this.$store.getters.designationByID;
      },
      departmentByID(){
        return this.$store.getters.departmentByID;
      },
    }
	}
</script>
<style scoped>
@import './../../../assets/css/style-dark.css';
</style>
<style>
	.listv-item.card
	{	
		margin: 4px;
    border: 2px solid lightblue;
	}
	.listGrid-listv .row
	{
		margin: 0px;
	}
    .small{
        height: 100px;
        width: 100px;
    }
</style>