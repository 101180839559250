<template>
	<div class="listGrid ">
		<div class="listGrid-toolbar mb-2 bg-custom text_custom">
			<span class="choosedepartment">
        <label>Select Department </label>
		<select class="" id="Sortbylist-Shop" v-model.number="department_id">
          <option :value="null">All</option>
          <option v-for="(val,key) in departments" :value="val.id" :key="key">{{ val.name }}</option>
        </select>
			</span>
			<span class="listGrid-switch-iconGroup ">
<!--				<input type="search" class="" id="gsearch" name="gsearch" placeholder="Search Faculty" style="margin-right:3px;">
				-->
        <i class="fa fa-list listGrid-switch-icon "
					v-bind:class="{ active: currentView=='list'}" 
					aria-hidden="true"
					:title="currentView=='grid' ? 'switch to List View': 'List View'"
					v-on:click="switchTogridv"><i data-feather="grid" class="fea icon-sm icons " ></i></i>
				<i class="fa fa-th listGrid-switch-icon"
					v-bind:class="{ active: currentView=='grid'}"
					:title="currentView=='list' ? 'switch to Grid View': 'Grid View'"
					aria-hidden="true" v-on:click="switchTolistv"><i data-feather="list" class="fea icon-sm icons " ></i></i>
					<!-- <i data-feather="list" class="fea icon-sm icons" ></i>			 -->
			</span>
		</div>
		<div class="listGrid-body">
			<div v-if="currentView=='list'" class="listGrid-gridv">
				<component :is="listRenderer" :employees="employees"></component>
			</div>
			<div v-if="currentView=='grid'" class="listGrid-listv">
				<component :is="gridRenderer" :employees="employees"></component>
			</div>
		</div>		
	</div>
</template>
<script>
	export default {
		name: 'listGrid',
		props: {
			'caption': { type:String, default: '' },
			'view': { type: String, default: 'grid' },
			'data': { default: () => {}	},
			'listRenderer': { default: () => { } },
			'gridRenderer': { default: () => { } }
		},
		data: function() {
			return {	
				currentView: this.view,	//initially set to same value as prop
        department_id:null,
			}
		},
		methods: {
			switchTogridv: function()
			{
				this.currentView = 'list';
			},
			switchTolistv: function()
			{
				this.currentView = 'grid';
			}
		},
    computed:{
      employees(){
        let employees = this.$store.getters.employees;
        if (this.department_id !== null) employees = employees.filter(item=>parseInt(item.department_id) === parseInt(this.department_id));
        return employees;
      },
      departments(){
		var academicFacultyList = this.$store.getters.departments.filter(function (el) {
		return el.id <= 18;
		});

        return academicFacultyList;
      },
    }
	}
</script>
<style scoped>
@import './../../../assets/css/style-dark.css';
</style>
<style>
#gsearch{
	height:27px;
}
	.listGrid
	{
		margin: 4px;
	}	
	.choosedepartment{
		float:left;
	}
	.listGrid-caption
	{
		font-weight: bold;
		left:50%
	}
	.listGrid-toolbar
	{
		width: 100%;
		height: 32px;
		line-height: 32px;
		background-color: rgba(58, 61, 51);
		padding-left: 8px;
	}
	@media (max-width: 600px) {
	.listGrid-toolbar
	{
		font-size: 0.5vw;
	}
	}
	.listGrid-switch-iconGroup
	{
		float: right;
		padding-right: 8px;
		padding-left: 8px;
	}
	.listGrid-switch-icon
	{
		padding: 4px;		
		border-radius: 4px;
		background-color: transparent;
		cursor: pointer;
	}
	.listGrid-switch-icon.active
	{
		background-color: rgba(123,127,109);
    color: #0c4128;
		cursor: default;
	}
</style>