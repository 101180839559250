<template>
  <div>
    <div class="row justify-content-center text_custom">
      <div class="card  col-md-4 m-2" v-for="(employee,key) in employees" style="width: 18rem;" :key="key">
        <img class="card-img-top mt-2" :src="employee.image?imgShow(employee.image):'/man_face.jpg'" alt="">
        <div class="card-body">
          <h5 class="card-title">{{employee.userable?employee.userable.name:''}}</h5>
          <span class="card-text">{{ designationByID(employee.designation_id) }}</span>
          <p class="card-text">Dept. of {{ departmentByID(employee.department_id) }}</p>
          <router-link :to="{path:`/faculty/details${employee.id}`}" exact class="btn btn-primary">See More</router-link>
        </div>
      </div>
    </div><!--end row-->

  </div>
</template>
<script>
export default {
  name: 'gridv',
  props: {
    'employees': { default: () => { } }
  },
  methods: {
    imgShow(path){
      return process.env.VUE_APP_ENV_RESOURCHURL+path;
    },
  },
  computed:{
    designationByID(){
      return this.$store.getters.designationByID;
    },
    departmentByID(){
      return this.$store.getters.departmentByID;
    },
  }
}
</script>
<style scoped>
@import './../../../assets/css/style-dark.css';
</style>